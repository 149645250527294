import getConfig from "next/config";
import { useRouter } from "next/router";
import type { IUSPComponentResponse } from "../types/componentResponses";
import Icon from "./icon";

interface IUniqueSellingPoints {
  usp: IUSPComponentResponse[];
}

function UniqueSellingPoints({ usp }: IUniqueSellingPoints): JSX.Element {
  const { publicRuntimeConfig } = getConfig();
  const router = useRouter();

  return (
    <div
      id="usp-container"
      className={`relative z-[11] mt-[2rem] bg-[#010202] px-[1rem] py-[0.5rem] desktop:mt-[3rem] desktop:h-[180px]`}
    >
      <div className="flex h-full flex-col items-center justify-center desktop:mx-auto desktop:max-w-[90rem] desktop:flex-row">
        {usp.map((element) => {
          let url = "";

          const handleClick = (url: string) => {
            if (url) return router.push(url);
          };

          if (element.USP_Text_Secondary_Url)
            url = `${publicRuntimeConfig.WEB_URL}${element.USP_Text_Secondary_Url}`;

          return (
            <div
              onClick={() => handleClick(url)}
              className={`hover:usp-item-hover flex w-full flex-col items-center border-b border-darkgray py-[1rem] last:border-b-0 last:border-r-0 hover:cursor-pointer desktop:border-b-0 desktop:border-r desktop:px-[70px]`}
              key={element.id}
            >
              {element.USP_Icon_Primary && (
                <Icon name={element.USP_Icon_Primary} width={44} height={44} />
              )}
              <span
                className={`my-2 text-p2 font-normal text-white underline ${
                  !element.USP_Icon_Primary ? "desktop:mt-[52px]" : ""
                }`}
              >
                {element.USP_Text_Primary}
              </span>
              <p className="text-p font-normal text-blue-20">
                {element.USP_Text_Secondary}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default UniqueSellingPoints;
