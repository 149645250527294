import DesignIcon from "@assets/icons/icon_design.svg";
import FormBackArrowIcon from "@assets/icons/icon_form_back_arrow.svg";
import SuperIcon from "@assets/icons/icon_super.svg";
import WorldIcon from "@assets/icons/icon_world.svg";

const iconTypes: { [key: string]: any } = {
  icon_world: WorldIcon,
  icon_design: DesignIcon,
  icon_super: SuperIcon,
  icon_form_back_arrow: FormBackArrowIcon,
};

interface IconComponentProps {
  name: string;
  width: number;
  height: number;
  className?: string;
}

const IconComponent = ({ name, ...props }: IconComponentProps) => {
  let Icon = iconTypes[name];
  return <Icon {...props} />;
};

export default IconComponent;
